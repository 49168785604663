













































































































































































import Vue from "vue";
import { Connection } from "@/store/connections/types";
import { PossibleConnectionsBody } from "@/api/connections";
import { Paging } from "@/store/utils/";
import { namespace } from "vuex-class";
import { Shop } from "@/store/shops/types";
import { Event } from "@sentry/types";

const connectionsModule = namespace("connections");
const shopsModule = namespace("shops");

export default Vue.extend({
  name: "PossibleConnections",

  computed: {
    loading: function () {
      return this.$store.state.connections.loading;
    },
    totalItems: function () {
      return this.$store.state.connections.totalItems;
    },
    possibleCustomers: function () {
      return this.$store.getters["connections/possibleCustomers"];
    },
    possibleProvinceCities: function () {
      return this.$store.getters["connections/possibleProvinceCities"];
    },
    connectionsSearch: function () {
      return this.$store.getters["connections/connectionsSearch"];
    },
    _pagination: {
      get() {
        return this.$store.state.connections.pagination;
      },
      set(value) {
        this.$store.dispatch("connections/paginate", value);
      },
    },
  },

  watch: {
    orgType: {
      deep: true,
      handler() {
        this.fetchConnections();
      },
    },
  },

  data: function () {
    return {
      distance: 10,
      distances: [
        { text: "10km", value: 10 },
        { text: "20km", value: 20 },
        { text: "30km", value: 30 },
        { text: "50km", value: 50 },
        { text: "100km", value: 100 },
      ],

      headers: [
        { text: "Customer Code", value: "customerCode" },
        { text: "Customer Name", value: "customerName" },
        { text: "Types", value: "types" },
        { text: "Contact Name", value: "contactName" },
        { text: "Province", value: "province" },
        { text: "City", value: "city" },
        { text: "Suburb", value: "suburb" },
        { text: "Distance", value: "distance" },
        { text: "Connect", value: "connect", align: "center" },
      ],

      deliveryPlace: "South Africa",
      province: "",
      provinces: [
        "Eastern Cape",
        "Free State",
        "Gauteng",
        "KwaZulu-Natal",
        "Limpopo",
        "Mpumalanga",
        "Northern Cape",
        "North West",
        "Western Cape",
      ],
      connectionStatus: "NONE",

      // FILTERS
      searchVal: "",
      orgType: 0,
      orgTypes: [
        {
          value: 0,
          label: "All",
        },
        {
          value: 4,
          label: "Shop",
        },
        {
          value: 3,
          label: "IDC",
        },
      ],

      code: "",
      name: "",
      add: false,

      connectionSelect: null,
      showConnectionSelect: false,

      paging: {
        page: 1,
        itemsPerPage: -1,
        descending: false,
        sortBy: [],
        filters: {},
      },
    };
  },

  mounted() {
    this.fetchConnections();
  },

  methods: {
    async onAddCustomer(toOrganisationId: number) {
      const success = await this.$store.dispatch(
        "connections/createNewConnection",
        toOrganisationId
      );
      if (success) this.fetchConnections();
    },
    async onAddCustomerByCode(toOrganisationId: number) {
      this.add = false;
      const success = await this.$store.dispatch(
        "connections/createNewConnection",
        toOrganisationId
      );
      this.code = "";
      if (success) this.$store.dispatch("connections/fetchConnections");
    },
    async onSearchCustomer() {
      let results = await this.$store.dispatch(
        "connections/fetchConnectionByOrgCode",
        { code: this.code }
      );
    },
    async onSearchCustomerName() {
      let results = await this.$store.dispatch(
        "connections/fetchConnectionByOrgName",
        { name: this.name }
      );
      this.showConnectionSelect = true;
    },
    clearSelection() {
      this.connectionSelect = null;
      this.name = "";
      this.showConnectionSelect = false;
    },
    toUpper(e: any) {
      this.code = e.target.value.toUpperCase();
    },
    fetchConnections() {
      if (this.deliveryPlace != "") {
        if (this.province) {
          this.deliveryPlace = `South Africa,${this.province}`;
        } else {
          this.deliveryPlace = `South Africa`;
        }
      }
      this.$store.dispatch("connections/fetchPossibleConnections", {
        collectionDistance: 0,
        type: this.orgType === 0 ? null : this.orgType,
        deliveryDistance: this.distance,
        deliveryPlace: this.deliveryPlace,
        connectionStatus: this.connectionStatus,
        excludePrimary: false,
        excludeCollection: true,
        excludeDelivery: true,
        page: 1,
        pagesize: this.paging.itemsPerPage,
      });
    },
    round(num: number) {
      return Math.round((num + Number.EPSILON) * 100) / 100 + "km";
    },
  },
});
